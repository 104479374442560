import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";

import Layout from "../layouts/en";
import styles from "../styles/info-site/PrivacyPolicy.module.css";

const tableOfContent = [
  {
    id: 'intro',
    hideTitle: true,
    title: 'Introduction',
    content: [
      {
        highlithed: 'We are a partnerships platform. Brands use our Services to find other brands to collaborate with. Our Privacy Policy applies to any Member or',
        paragraph: <p>Our registered users (“Members”) create ads and customise their pages. Content and data on some of our Services are viewable to non-members (“Visitors”).</p>
      },
      {
        subTitle: 'Services',
        highlithed: 'This Privacy Policy, including our Cookie Policy, applies to your use of our Services.',
        paragraph: <p>This Privacy Policy applies to Tyviso but excludes services that state that they are offered under a different privacy policy.</p>
      },
      {
        subTitle: 'Data Controllers and Contracting Parties',
        paragraph: <p>Tyviso is the controller of your personal data provided to, or collected by or for, or processed in connection with our Services.</p>
      },
      {
        subTitle: 'Change',
        highlithed: 'Changes to the Privacy Policy apply to your use of our Services after the “effective date.”',
        paragraph: <>
          <p>Tyviso (“we” or “us”) can modify this Privacy Policy. If we make material changes to it, we will provide notice through our Services or by other means to provide you the opportunity to review the changes before they become effective. If you object to any changes, you may close your account.</p>
          <p>You acknowledge that your continued use of our Services after we publish or send a notice about our changes to this Privacy Policy means that the collection, use and sharing of your data is subject to the updated Privacy Policy, as of its effective date.</p>
        </>
      },
    ]
  },
  {
    id: 'data-collect',
    title: 'Data We Collect',
    content: [
      {
        subTitle: '1.1 Data You Provide To Us',
        isOrdered: true,
        highlithed: 'You provide data to create an account with us.',
      },
      {
        subTitle: 'Registration',
        paragraph: <p>To create an account you need to provide data including your name and email </p>
      },
      {
        subTitle: '1.2 Data From Others',
        isOrdered: true,
      },
      {
        subTitle: 'Partners',
        paragraph: <p>We receive personal data (e.g., click & conversion data) when you use our service via third party partners. This data is anonymised.</p>
      },
      {
        subTitle: '1.3 Data From Others',
        highlithed: 'We log your visits and use of our Services, including mobile apps.',
        isOrdered: true,
        paragraph: <p>We log usage data when you visit or otherwise use our Services, including our site and platform technology.</p>,
      },
      {
        subTitle: '1.4 Cookies and Similar Technologies',
        highlithed: 'We collect data through cookies and similar technologies.',
        isOrdered: true,
        paragraph: <p>We use cookies and similar technologies to collect data (e.g., device IDs) to recognise you and your device(s) on, off and across different services and devices where you have engaged with our Services via our website.</p>,
      },
      {
        subTitle: '1.5 Your Device and Location',
        highlithed: 'We receive data through cookies and similar technologies',
        isOrdered: true,
        paragraph: <p>When you visit or leave our Services (including some plugins and our cookies or similar technology on the sites of others), we receive the URL of both the site you came from and the one you go to and the time of your visit. We also get information about your network and device (e.g., IP address, proxy server, operating system, web browser and add-ons, device identifier and features, cookie IDs and/or ISP, or your mobile carrier). If you use our Services from a mobile device, that device will send us data about your location based on your phone settings. </p>,
      },
      {
        subTitle: '1.6 Other',
        highlithed: 'We are improving our Services, which means we get new data and create new ways to use data.',
        isOrdered: true,
        paragraph: <p>Our Services are dynamic, and we often introduce new features, which may require collecting new information. If we collect materially different personal data or materially change how we collect, use or share your data, we will notify you and may also modify this Privacy Policy.</p>,
      },
    ]
  },
  {
    id: 'use-data',
    title: 'How We Use Your Data',
    content: [
      {
        highlithed: 'We use your data to provide, support, personalise develop our Services.',
        paragraph: <p>How we use your personal data will depend on which Services you use, how you use those Services and the choices you make in your settings. We use the data that we have about you to provide and personalise our Services, including with the help of automated systems and inferences we make so that our Services can be more relevant and valuable to you and others.</p>
      },
      {
        subTitle: '2.1 Services',
        highlithed: 'Our Services help you connect with others, find and be found for work and business opportunities.',
        isOrdered: true,
        paragraph: <p>We use your data to authorise access to our Services and honour your settings.</p>,
      },
      {
        subTitle: '2.2 Communications',
        highlithed: 'We contact you and enable communications between Brands. We offer settings to control what messages you receive and how often you receive some types of messages.',
        isOrdered: true,
        paragraph: <p>We will contact you through email, mobile phone, and notices posted on our websites or apps.</p>,
      },
      {
        subTitle: '2.3 Marketing',
        isOrdered: true,
        highlithed: 'We promote our Services to you and others.',
        paragraph: <p>For example, new platforms that we may launch.</p>
      },
      {
        subTitle: '2.4 Customer Support',
        isOrdered: true,
        highlithed: 'We use data to help you and fix problems.',
        paragraph: <p>We use data (including your communications) to investigate, respond to and resolve complaints and service issues (e.g., bugs).</p>
      },
      {
        subTitle: '2.5 Insights That Do Not Identify You',
        isOrdered: true,
        highlithed: 'We use data to generate insights that do not identify you.',
        paragraph: <p>We use your data to produce and share insights that do not identify you. For example, we may use your data to generate statistics, calculate ad impressions served or clicked on or other similar insights.</p>
      },
      {
        subTitle: '2.6 Security and Investigations',
        isOrdered: true,
        highlithed: 'We use data for security, fraud prevention and investigations.',
        paragraph: <p>We use your data (including your communications) for security purposes or to prevent or investigate possible fraud or other violations of our User Agreement and/or attempts to harm our Members, Visitors or others.</p>
      },
    ]
  },
  {
    id: 'share-info',
    title: 'How We Share Information',
    content: [
      {
        subTitle: '3.1 Service Providers',
        isOrdered: true,
        highlithed: 'We may use others to help us with our Services.',
        paragraph: <p>We use others to help us provide our Services (e.g., maintenance, analysis, audit, payments, fraud detection, marketing and development). They will have access to your information as reasonably necessary to perform these tasks on our behalf and are obligated not to disclose or use it for other purposes.</p>
      },
      {
        subTitle: '3.2 Legal Disclosures',
        isOrdered: true,
        highlithed: 'We may need to share your data when we believe it’s required by law or to help protect the rights and safety of you, us or others.',
        paragraph: <p>It is possible that we will need to disclose information about you when required by law, subpoena, or other legal process or if we have a good faith belief that disclosure is reasonably necessary to (1) investigate, prevent or take action regarding suspected or actual illegal activities or to assist government enforcement agencies; (2) enforce our agreements with you; (3) investigate and defend ourselves against any third-party claims or allegations; (4) protect the security or integrity of our Services (such as by sharing with companies facing similar threats); or (5) exercise or protect the rights and safety of Tyviso, our Members, personnel or others. We attempt to notify Members about legal demands for their personal data when appropriate in our judgment unless prohibited by law or court order or when the request is an emergency. We may dispute such demands when we believe, in our discretion, that the requests are overbroad, vague or lack proper authority, but we do not promise to challenge every market. </p>
      },
      {
        subTitle: '3.3 Change in Control or Sale',
        isOrdered: true,
        highlithed: 'We may share your data when our business is sold to others, but it must continue to be used in accordance with this Privacy Policy.',
        paragraph: <p>We can also share your personal data as part of a sale, merger, change in control, or preparation for any of these events. Any other entity which buys us or part of our business will have the right to continue to use your data, but only in the manner set out in this Privacy Policy unless you agree otherwise.</p>
      },
    ]
  },
  {
    id: 'choises',
    title: 'Your Choices and Obligations',
    content: [
      {
        subTitle: '4.1 Data Retention',
        isOrdered: true,
        highlithed: 'We keep most of your personal data for as long as your account is open.',
        paragraph: <p>We generally retain your personal data as long as you keep your account open or as needed to provide you with Services. This includes data you or others provided to us and data generated or inferred from your use of our Services. In some cases, we retain certain information (e.g., insights about Services use) in a depdepersonalised aggregated form.</p>
      },
      {
        subTitle: '4.2 Rights to Access and Control Your Personal Data',
        isOrdered: true,
        highlithed: 'You can access or delete your personal data. You have many choices about how your data is collected, used and shared.',
        paragraph: <>
          <p>For personal data that we have about you, you can:</p>
          <ul>
            <li>Delete Data: You can ask us to erase or delete all or some of your personal data (e.g., if it is no longer necessary to provide Services to you).</li>
            <li>Change or Correct Data: You can edit some of your personal data through your account. You can also ask us to change, update or fix your data in some instances, particularly if it’s inaccurate.</li>
            <li>Object to, or Limit or Restrict, Use of Data: You can ask us to stop using all or some of your personal data (e.g., if we have no legal right to keep using it) or to limit our use of it (e.g., if your personal data is inaccurate or unlawfully held).</li>
            <li>Right to Access and/or Take Your Data: You can ask us for a copy of your personal data and can ask for a copy of personal data you provided in machine-readable form.</li>
          </ul>
        </>
      },
      {
        subTitle: '4.3 Account Closure',
        isOrdered: true,
        highlithed: 'We keep some of your data even after you close your account.',
        paragraph: <>
          <p>If you choose to close your Tyviso account, your personal data will generally stop being visible to others on our Services within 24 hours. We generally delete closed account information within 30 days of account closure, except as noted below.</p>
          <p>We retain your personal data even after you have closed your account if reasonably necessary to comply with our legal obligations (including law enforcement requests), meet regulatory requirements, resolve disputes, maintain security, prevent fraud and abuse (e.g., if we have restricted your account for breach of our policies), enforce our User Agreement, or fulfil your request to "unsubscribe" from further messages from us. We will retain de-personalised information after your account has been closed.</p>
        </>
      },
    ]
  },
  {
    id: 'other-info',
    title: 'Other Important Information',
    content: [
      {
        subTitle: '5.1 Security',
        isOrdered: true,
        highlithed: 'We monitor for and try to prevent security breaches. Please use the security features available through our Services.',
        paragraph: <p>We implement security safeguards designed to protect your data, such as HTTPS. We regularly monitor our systems for possible vulnerabilities and attacks. However, we cannot warrant the security of any information that you send us. There is no guarantee that data may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards. We use two-factor authentication to minimalise risk. </p>
      },
      {
        subTitle: '5.2 Cross-Border Data Transfers',
        isOrdered: true,
        highlithed: 'We store and use your data in the UK only',
        paragraph: <p>This point will be updated upon Tyviso expanding into new territories outside the UK.</p>
      },
      {
        subTitle: '5.3 Lawful Bases for Processing',
        isOrdered: true,
        highlithed: 'We have lawful bases to collect, use and share data about you. You have choices about our use of your data. At any time, you can withdraw consent you have provided by going to settings.',
        paragraph: <>
          <p>We will only collect and process your personal data where we have lawful bases. Lawful bases include consent(where you have given consent), contract where processing is necessary for the performance of a contract with you and “legitimate interests.” </p>
          <p>Where we rely on your consent to process personal data, you have the right to withdraw or decline your consent at any time and where we rely on legitimate interests, you have the right to object.  </p>
        </>
      },
      {
        subTitle: '5.4 Promotion Of Collaboration',
        isOrdered: true,
        highlithed: 'You agree that other brands can promote that they are partnering with you.',
        paragraph: <>
          <p>Occasionally, our partners like to promote who they work with to their customers.</p>
          <p>You irrevocably consent without limitation that you approve of this and that our partners may use brand assets such as your logo, trademark, company name or similar whilst doing so.</p>
        </>
      },
    ]
  },
]

const Terms = props => {
    return (
        <Layout light location={props.location}>
            <div className={styles.PrivacyPolicy}>
              <h1>Terms & Conditions</h1>
              
              <div className={styles.featured}>
        <h2>Your Privacy Matters</h2>
        <p>Tyviso’s mission is to help brands partner together at scale. Central to this mission is our commitment to being transparent about the data we collect about you, how it is used and with whom it is shared. </p>
        <p>This Privacy Policy applies when you use our Services or Website (described below).</p>
      </div>
      <div className={styles.content}>
        <h2 className={styles.contentTitle}>Table of content</h2>
        <ol>
          {
            tableOfContent.slice(1).map(({ id, title }) => (
              <li className={styles.contentOrderItem} key={id}><button className={styles.tab} tabIndex="-1" onClick={() => { document.querySelector(`[id="${id}"]`).scrollIntoView() }}>{title}</button></li>
            ))
          }
        </ol>

        <div className={styles.policyContent}>
          <ol>
            {
              tableOfContent.map(({ id, title, content }, index) => (
                <li key={id}>
                  <h2 id={id}>{`${index > 0 ? index : ''} ${title}`}</h2>
                  <div>
                    {
                      content.map(({ subTitle, isOrdered, highlithed, paragraph }, idx) => (
                        <Fragment key={`${id}--${idx}`}>
                          {isOrdered ? <h3>{subTitle}</h3> : <h4>{subTitle}</h4>}
                          {
                            highlithed && (
                              <div className={styles.highlithed}>
                                <span><FontAwesomeIcon icon={faLightbulb} /></span>
                                <p>{highlithed}</p>
                              </div>
                            )
                          }
                          {
                            paragraph && (
                              <div>
                                {paragraph}
                              </div>
                            )
                          }
                        </Fragment>
                      ))
                    }
                  </div>
                </li>
              ))
            }
          </ol>
        </div>
      </div>
            </div>
        </Layout>
    );
};

export default Terms;